@import "~antd/dist/antd.less";
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@200;300;400;500;600;700&display=swap');

@media only screen and (max-width: 1440px) {
  body {
    font-size: 12px;
  }
}

p,ul,ol{
  margin-bottom: 0px;
}

body{
  font-family: 'public sans';
  width: 100% !important;
  max-width: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
  background: #fff !important;
  display: block!important;
  height: auto!important;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; 
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CCCED3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.menu-popper {
  .ant-popover-arrow-content {
    background: #fff;
    box-shadow: 0px -2px 4px rgba(29, 141, 204, 0.25), 0px 2px 4px rgba(29, 141, 204, 0.25);
  }
  .ant-popover-inner {
    background: #fff;
    box-shadow: 0px -2px 4px rgba(29, 141, 204, 0.25), 0px 2px 4px rgba(29, 141, 204, 0.25);
  }
}

.main-tabpanel{
  .ant-tabs-tab-active{
    border-bottom: 6px solid var(--color-primary) !important;
    font-weight: 600 !important;
  }
}

.sub-tabpanel{
  .ant-tabs-tab-active{
    border-bottom: 4px solid var(--color-primary) !important;
    font-weight: 600 !important;
  }
}

.ant-drawer-content-wrapper{
  height: 650px !important;
}

.ant-drawer-content{
  border-radius: 20px 20px 0px 0px;
}

.ant-drawer.ant-drawer-open .ant-drawer-mask{
  width: 105.4%;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

.layout {
  .sider {
    width: 65px !important;
    max-width: 65px !important;
    min-width: 65px !important;
    .ant-layout-sider-children {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }

    .ant-image {
      margin: 20px 10px;
    }

    .ant-avatar {
      margin: 20px 0px;
    }
  }

  .top-aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .app-menu {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .ant-menu-item-selected {
        background: #DDEEF8;
      }
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 2px !important;
        .ant-menu-title-content {
          margin: 0px !important;
        }
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  .bottom-aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .bottom-menu {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
}

#transitions-popper {
  z-index: 99;
  background-color: #fff;
  margin-bottom: 10px;
}

.header-menu-icon {
  .ant-dropdown-menu-item-icon {
    margin-right: 0px;
  }
}

.ant-tabs-nav{
  margin-bottom: 10px !important;
}
.ant-modal-close-icon:hover{
  background: rgba(71, 71, 71, 0.2);
  padding: 3px;
}

.template-modal{
  .ant-modal-content{
    height: 100% !important;
  }
  .ant-collapse-header{
    padding: 12px 40px 12px 0px !important;
  }
}
.ant-picker-suffix{
  color: var(--color-primary);
  font-size: 14px !important;
}

.ant-picker-input > input{
  ::placeholder, ::-webkit-input-placeholder, ::-moz-placeholder{
    font-size: 14px !important;
    color: #959595 !important;
  }
}

.anticon-calendar,.anticon-close-circle{
  font-size: 17px !important;
}

.ant-select-selection-item{
  font-size: 14px !important;
  color: #959595;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner{
  background: #d5d5d5;
}

.ant-picker-header > button{
  min-width: 3em;
}

.ant-select-arrow{
  color: #474747;
}

.react-tags {
  position: relative;
  border-radius: 1px;
  font-size: 0.8em;
  line-height: 1.2;
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 4px 8px;
  border: none;
  color: #4790bb;
  background: rgba(71, 144, 187, 0.3);
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;
  padding: 7px 2px;
  max-width: 100%;
}

.react-tags__search-wrapper {
  border: 1px dashed  var(--color-primary);
  padding: 2px 5px;
}

.react-tags__search-input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-webkit-input-placeholder{
  color: var(--color-primary) !important;
  font-weight: 500;
}

.react-tags__search-input::-moz-placeholder{
  color: var(--color-primary) !important;
  font-weight: 500;
}

.react-tags__search-input:-ms-input-placeholder{
  color: var(--color-primary) !important;
  font-weight: 500;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

.react-tags__suggestions ul {
  margin: 3px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.slick-prev::before, .slick-next::before{
  display: none !important;
}

.slick-next{
  right: -12px !important;
  z-index: 99;
  width: 18px;
  height: 18px;
  top: 16px;
}

.slick-prev{
  left: -14px !important;
  z-index: 99;
  width: 18px;
  height: 18px;
  top: 16px;
}

.tag-container{
  display: none !important;
}

.rdw-editor-main {
  padding: 10px;
}

.rdw-fontfamily-wrapper,.rdw-colorpicker-wrapper, .rdw-remove-wrapper, .rdw-history-wrapper, .rdw-text-align-wrapper{
  display: none;
}

.rdw-image-modal-upload-option-label {
  overflow: hidden;
}
@primary-color: #3F578D;@text-color: #3F578D;